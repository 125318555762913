import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import Details from '../components/cards/Details';
import Entities from '../components/cards/Entities';
import Events from '../components/cards/Events';
import { getAsync } from '../atoms/searchEventHandler';
import Nameservers from '../components/cards/Nameservers';
import Notices from '../components/cards/Notices';
import Variants from '../components/cards/Variants';
import { searchFilterAtom } from '../atoms/searchAtoms';

const Resource = () => {
  const [resource, setResource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location?.search);
  const [searchFilter,] = useAtom(searchFilterAtom);
  const keyword = searchParams.get('keyword');
  const resourceType = searchParams.get('type');
  const navigate = useNavigate();

  async function getResource() {
    setIsLoading(true);

    const result = await getAsync(keyword, resourceType);

    setResource(result);
    setIsLoading(false);
  }

  function goBack() {
    if (searchFilter.type === 'entity' || searchFilter.type === 'registrar' || searchParams.get('bth') == 'true' ) { // bth = back to home page 
      navigate('/');
    } else if (searchFilter.keyword) {
      navigate(-1);
    } else {
      navigate('/search');
    }
  }

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Container id='rdap-resource-container' maxWidth="lg" sx={{ marginTop: 10, p: 2 }}>
      <Grid container justifyContent="center">
        <Grid xs={12} md={8}>
          <Typography sx={{ marginLeft: '50%', marginRight: '50%'}}>{isLoading && <CircularProgress sx={{ mt: 3 }} />}</Typography>
          {isLoading === false && 
            <Stack direction='column' spacing={2}>
              <Button id='rdap-back-to-search-results-button' variant="contained" onClick={goBack} sx={{ width: '70px'}}>
                <ArrowBackIcon />
              </Button>
              <Details data={resource} />
              <Events data={resource?.events} />
              <Variants data={resource?.variants} />
              <Entities data={resource?.entities} />
              { resource?.vcardArray && <Entities data={[resource]} expandAll={true} /> }
              <Nameservers data={resource?.nameservers} />
              <Notices data={resource?.notices} />
            </Stack>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default Resource;
